<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card class="dialog">
      <q-card-section class="column items-center">
        <h3 class="dialog-title">
          Tem certeza que deseja encerrar a aula ao vivo?
        </h3>
      </q-card-section>
      <hr />
      <q-card-actions align="between">
        <SecundaryButton label="Voltar" @onClick="$emit('close')" medium />
        <TertiaryButton
          label="Encerrar aula"
          @onClick="endLesson"
          medium
          colorLabel="#E62E2E"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, watch } from "vue";

import SecundaryButton from "@/components/liveClass/comumBtn/SecundaryButton.vue";
import TertiaryButton from "@/components/liveClass/comumBtn/TertiaryButton.vue";
export default {
  name: "DialogShutUpLiveClass",
  emits: ["close", "shutUpLive"],
  props: {
    showDialog: Boolean,
    endLesson: Function,
  },
  components: {
    SecundaryButton,
    TertiaryButton,
  },
  setup(props) {
    let isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      isShowDialog,
    };
  },
};
</script>

<style scoped lang="scss">
.dialog {
  width: 475px;
  max-width: 80vw;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  &-title {
    color: var(--grayscale-grayscale-80, #2e2e2e);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.01563rem;
    margin-bottom: 26.5px;
  }
  &-caption {
    color: var(--grayscale-grayscale-70, #666);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.01563rem;
    margin-bottom: 48px;
  }
}
</style>
